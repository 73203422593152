import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import {
	PiHcAccount, PiHomeFill,
} from '@primeinsightsgroupllc/prime-icons';
import { USER_ROUTE_NAME } from '@/constants/routes';

export const useNavItems = () => {
	const { t } = useI18n();

	const items = computed<Array<NavItem>>(() => [
		{
			icon: PiHomeFill,
			label: t('Surveys'),
			to: USER_ROUTE_NAME.SURVEYS,
			isActive: true,
			id: USER_ROUTE_NAME.SURVEYS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiHcAccount,
			label: t('Account'),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['desktop', 'mobile'],
		},
	]);

	const filterItems = (
		items: NavItem[],
		type: 'mobile' | 'desktop'
	): NavItem[] => {
		return items.filter((item) => item.placed.includes(type) && item.isActive);
	};

	const desktopItems = computed(() => filterItems(items.value, 'desktop'));
	const mobileItems = computed(() => filterItems(items.value, 'mobile'));

	return {
		desktopItems,
		mobileItems,
		items,
	};
};
