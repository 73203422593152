<script setup lang="ts">
import {
	NO_CONNECTION_MESSAGE,
	NO_CONNECTION_TITLE,
	RELOAD,
} from '@/locales/constants';
import NoConnectionIcon from '@/components/NoConnectionIcon.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const reload = () => {
	document.location.reload();
};
</script>

<template>
	<div class="no-connection-wrapper">
		<NoConnectionIcon />
		<div class="no-connection-content">
			<PrimeText tag="div" size="xl" weight="500" class="title">
				{{ $t(NO_CONNECTION_TITLE) }}
			</PrimeText>
			<PrimeText
				tag="div"
				size="sm"
				weight="500"
				color="grey-700"
				class="message"
			>
				{{ $t(NO_CONNECTION_MESSAGE) }}
			</PrimeText>
		</div>
		<PrimeButton
			full-width
			:label="$t(RELOAD)"
			@click="reload"
		/>
	</div>
</template>

<style lang="scss">
.no-connection-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 90%;
	max-width: 21.25rem;
	text-align: center;

	& .button {
		width: 100%;
	}
}

.no-connection-content {
	margin: 1.5rem 0;

	& .message {
		margin: 1rem 0 2rem;
	}
}
</style>
