<script setup lang="ts">
import { useScreenSize } from '@/utils/composables/useScreenSize';
import {
  PrimeSidebar, PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
  PiAccountBalanceWallet,
  PiHcChevron_right,
  PiCheckCircleFill,
  PiHcCard
} from "@primeinsightsgroupllc/prime-icons";

const { isLaptop, isTablet } = useScreenSize();

</script>

<template>
	<PrimeSidebar
		v-if="!isTablet"
		:width="isLaptop ? '15rem' : '21.25rem'"
		position="right"
    class="right-sidebar"
	>
    <div class="right-sidebar-card">
      <PrimeText size="sm" weight="500" color="white">{{ $t('Balance:') }}</PrimeText>
      <PrimeText size="3xl" weight="700" color="white">{{ $t('$50 USD') }}</PrimeText>
    </div>
    <PrimeText size="lg" weight="600">{{ $t('Account statistics') }}</PrimeText>
    <div class="statistic-item">
      <div class="statistic-item__content">
        <PiAccountBalanceWallet color="base-primary"/>
        <div class="statistic-item__info">
          <PrimeText size="lg" weight="600">{{ $t('$500 USD') }}</PrimeText>
          <PrimeText size="sm" weight="500" color="grey-500">{{ $t('Total earnings') }}</PrimeText>
        </div>
      </div>
      <PiHcChevron_right />
    </div>
    <div class="statistic-item">
      <div class="statistic-item__content">
        <PiCheckCircleFill color="base-primary"/>
        <div class="statistic-item__info">
          <PrimeText size="lg" weight="600">{{ $t('298') }}</PrimeText>
          <PrimeText size="sm" weight="500" color="grey-500">{{ $t('Surveys completed') }}</PrimeText>
        </div>
      </div>
      <PiHcChevron_right />
    </div>
    <div class="statistic-item">
      <div class="statistic-item__content">
        <PiHcCard color="base-primary"/>
        <div class="statistic-item__info">
          <PrimeText size="lg" weight="600">{{ $t('29') }}</PrimeText>
          <PrimeText size="sm" weight="500" color="grey-500">{{ $t('Rewards redeemed') }}</PrimeText>
        </div>
      </div>
      <PiHcChevron_right />
    </div>
  </PrimeSidebar>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.right-sidebar {
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  background: var(--p-grey-100);
  &-card {
    height: 127px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 24px;
    width: 100%;
    background: var(--p-primary-base) url('@/assets/card-gradient.svg');
    margin-bottom: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }
}

.statistic-item {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--p-grey-300);
  padding: 0.75rem;
  border-radius: 0.75rem;
  cursor: pointer;
  &__content {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }
  &__info {
    display: flex;
    flex-direction: column;
  }
}
</style>
