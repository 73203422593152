<script lang="ts" setup>
import { onMounted, ref, watch, computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import AppSidebar from '@/components/AppSidebar.vue';
import AppSidebarRight from '@/components/AppSidebarRight.vue';
import AppMobileNav from '@/components/AppMobileNav.vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import { getInitialUserLocalization } from '@/utils/helpers';
import { handleUserLocale } from '@/i18n';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import NoConnection from '@/components/NoConnection.vue';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useAppRerender } from '@/utils/composables/useAppRerender';
import { useBlockScroll } from '@/utils/composables/useBlockScroll';
import { useCapacitorPlugins } from '@/utils/composables/useCapacitorPlugins';
import { useLiveChat } from '@/utils/composables/useLiveChat';
import { providePrimeSurveys } from '@/utils/composables/useIntegrationScriptRender';
import { providePrimeIcons } from '@primeinsightsgroupllc/prime-icons';
import '@primeinsightsgroupllc-ui/prime-ui-kit/dist/main.css';
import {
  providePrimeTheme,
  PrimeLoaderSpinner,
  PrimeNotificationsContainer,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useTranslateDetection } from '@/utils/composables/useTranslateDetection';
import { useAppPusherEventListener } from '@/utils/composables/app/useAppPusherEventListener';
import { useAppNotificationListener } from '@/utils/composables/app/useAppNotificationListener';
import { useAppInit } from '@/utils/composables/app/useAppInit';
import { providePrimeChat } from '@/utils/composables/useIntegrationChatRender';
// import PrimeChat from '@/components/PrimeChat.vue';
import { useAppAnnouncementsListener } from '@/utils/composables/app/useAppAnnouncementsListener';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { useUserStore } from '@/stores/user';
import { onUnmounted } from 'vue';
import { useAppStore } from '@/stores/app';
import { MODAL_MAP } from '@/constants/modals-map';
import { THEME_APP, THEME_ICONS } from "@/constants/theme";
import {
  PrimeModalWindow,
  useModalStorage,
} from '@primeinsightsgroupllc-modals/prime-modals';

providePrimeTheme();
providePrimeIcons();
providePrimeSurveys();
providePrimeChat();
useBlockScroll();
const { isPrimeChatEnabled } = useLiveChat();
useTranslateDetection();
const { init } = useAppInit();

const { setThemeColors } = providePrimeTheme();
const themeContainer = ref<HTMLElement | undefined>(undefined);

setThemeColors(THEME_APP, themeContainer.value);

const { setIconsColors } = providePrimeIcons();

setIconsColors(THEME_ICONS);

const { localizationCookie } = useAppCookies();
const route = useRoute();
const authStore = useAuthStore();
const { isAuthorized, isTopNavVisible } = storeToRefs(authStore);
const { isMobileApp } = useMobileApp();
const { appKey } = useAppRerender();
const { isNetworkConnected, initCapacitor, deinitCapacitor } =
    useCapacitorPlugins(init);
const { isInitialDataLoading } = storeToRefs(useUserStore());
const appStore = useAppStore();
const { isMobile } = useScreenSize();
// const { handleRouteChange, shiftModal } = useModalStorage();

useAppPusherEventListener();
useAppNotificationListener();
useAppAnnouncementsListener();

const isMobileNavShown = ref(false);
const localizationLoading = ref(false);

onMounted(async () => {
  await initCapacitor();
  let localization = localizationCookie;

  if (!localization) {
    localizationLoading.value = true;
    try {
      localization = await getInitialUserLocalization();
    } catch (e) {
      sendErrorInfo(e);
    } finally {
      localizationLoading.value = false;
    }
  }

  await handleUserLocale(localization);
});

watch([isAuthorized, route], (data) => {
  const [isAuthorized, route] = data;
  const isVisible = Boolean(isAuthorized && !route.meta.hideMobileNav);

  if (isMobileNavShown.value !== isVisible) {
    isMobileNavShown.value = isVisible;
  }
});

const isPublicPage = computed(() => {
  return route?.name && route?.meta?.isPublic;
});

const isAppLoginPage = computed(
    () => route?.name === 'app-login' || route?.name === 'home'
);

const showLayoutWithSiebars = computed(() => {
  return route.name && !route.meta.withoutHeader && !isAppLoginPage.value;
});

const showUserBalance = computed(
    () =>
        showLayoutWithSiebars.value &&
        (!route.meta.hideMobileBalance || !isMobile.value)
);

onMounted(() => {
  // Close active modal if page reload or tab close
  if (!isMobileApp) {
    // window.addEventListener('beforeunload', shiftModal);
  }
});

onUnmounted(() => {
  deinitCapacitor();
  appStore.clearLog();
});

// Close active modal on route change
// watch(
//     () => route.name,
//     async () => await handleRouteChange(route.name as string)
// );

const contentPadding = computed(() => {
  return route.name && route.meta.isPublic
      ? 'env(safe-area-inset-top)'
      : 'calc(3.75rem + env(safe-area-inset-top))';
});
</script>

<template>
  <ErrorBoundary>
    <div
        class="app-layout"
        :class="{
				'is-login': isAppLoginPage,
				logged: showLayoutWithSiebars,
				'user-balance-showed': showUserBalance,
			}"
    >
      <template v-if="isNetworkConnected">
        <PrimeLoaderSpinner
            v-if="localizationLoading || isInitialDataLoading"
        />
        <template v-else>
          <AppSidebar v-if="showLayoutWithSiebars" />
          <div
              :key="appKey"
              class="app-content"
              :class="{
							background: !isPublicPage,
							'header-visible': !isTopNavVisible && !showLayoutWithSiebars,
							'app-login-content': isAppLoginPage,
						}"
          >
            <RouterView />
          </div>
          <AppSidebarRight v-if="showLayoutWithSiebars" />
          <AppMobileNav v-if="isMobileNavShown" />
          <PrimeNotificationsContainer />
          <!-- Modals -->
        </template>
<!--        <PrimeModalWindow :modals="MODAL_MAP" />-->
        <!--TODO: Refactor to reinit resize observer as exposed method -->
<!--        <PrimeChat-->
<!--            v-if="isPrimeChatEnabled"-->
<!--            :key="+isMobileNavShown + appKey"-->
<!--        />-->
      </template>
      <div v-else class="app-content centered">
        <NoConnection />
      </div>
    </div>
  </ErrorBoundary>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.app-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-height: 100svh;
  overflow: auto;
  position: relative;

  @include breakpoint(tablet) {
    min-height: 100vh;
  }

  &.logged {
    flex-direction: row;
    align-items: unset;

    @include breakpoint(tablet) {
      padding: 0 15rem;
    }

    @include breakpoint(laptop) {
      padding-left: 16.25rem;
      padding-right: 21.25rem;
    }
  }
  &.logged .app-content {
    position: relative;
    flex: 1;
    width: unset;
    padding: calc(0.75rem + env(safe-area-inset-top)) 1rem 5.5rem;
    @include breakpoint(mobile) {
      padding: 0.75rem 1.313rem 5.5rem;
    }
    @include breakpoint(tablet) {
      padding: 1.5rem 1.313rem 2.25rem;
    }
  }
  & .app-content.centered {
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  & .app-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
    overflow: auto;
    padding-top: calc(3.75rem + env(safe-area-inset-top));

    &.app-login-content {
      padding-top: 0;
    }

    &.header-visible {
      padding-top: env(safe-area-inset-top);

      @include breakpoint(mobile) {
        padding-top: v-bind(contentPadding);
      }
    }
  }

  :deep(.p-skeleton) {
    background-color: var(--grey-200);
  }
}
.inner-layout {
  display: flex;
  width: 100%;

  .app-content {
    flex: 1;
    padding-top: 1.625rem;
  }
}

.p-modal-mask :deep(.p-modal-content),
.p-modal-mask :deep(.p-modal-bottom) {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  @include breakpoint(mobile) {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
}
</style>
