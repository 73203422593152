<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
// import logo_mock from '@/assets/logo_mock.svg';
import DesktopNav from '@/components/AppDesktopNav.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { PrimeSidebar } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {useScreenSize} from "@/utils/composables/useScreenSize";

const route = useRoute();
const { isAuthorized } = storeToRefs(useAuthStore());

const { isLaptop, isTablet } = useScreenSize();

const authStepsPages = computed(() => {
	return route.name && route.meta.withoutHeader;
});
</script>

<template>
	<PrimeSidebar
      v-if="!authStepsPages"
      class="app-sidebar"
      :width="isLaptop ? '15rem' : '16.25rem'">
		<div class="app-sidebar-logo">
			<router-link :to="{ name: 'home' }">
        Logo place
<!--				<img :src="logo_mock" alt="Logo" />-->
			</router-link>
		</div>
		<div>
			<DesktopNav
				v-if="isAuthorized"
				class="app-header__col app-header__col--desktop-menu"
			/>
		</div>
	</PrimeSidebar>
</template>

<style scoped lang="scss">
.app-sidebar {
  display: flex;
  flex-direction: column;
  &-logo {
    /* placeholder */
    align-self: center;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 36px;
    flex-shrink: 0;
    background: #E5E7EB;
    margin-bottom: 2.75rem;
  }
}
</style>
